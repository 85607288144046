import { useTranslation } from 'react-i18next'
import s from './DonateWindow.module.scss'

export const DonateWindow = () => {
    const { t } = useTranslation()
    return (
        <a href='https://boosty.to/asistent.ai/donate' 
            target='_blank' 
            className={s.donate_container}
            rel='noreferrer'>
            <div className={s.info}>
                <div className={s.title}>
                    <h1>{t('donateTitle')}</h1>                   
                </div>
                <div className={s.text}>
                {t('donateText')}
                </div>
                <div className={s.button}>
                {t('donateButton')}
                </div>
            </div>
        </a>
    )
}