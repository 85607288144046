import { useTranslation } from 'react-i18next'
import { NavigationBar } from '../../Components/NavigationBar/NavigationBar'
import s from './WebAssistant.module.scss'
import { OtherPlatforms } from '../../Components/OtherPlatforms/OtherPlatforms'
import { Footer } from '../../Components/Footer/Footer'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Answer } from '../../Components/Answer/Answer'
import { InputArea } from '../../Components/InputArea/InputArea'
import { ReactComponent as ErrorSvg } from './attention.svg'
import { ReactComponent as Ring } from './ring.svg'
import { ReactComponent as Cross } from '../../assets/cross.svg'
import { SelectBotsRole } from '../../Components/SelectBotsRole/SelectBotsRole'
import { DonateWindow } from '../../Components/DonateWindow/DonateWindow'

export const WebAssistant = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    const { t } = useTranslation()
    const { textMessages, error, status, botsRole } = useSelector(state => state.assistant)
    const messageAreaRef = useRef(null)
    const [notification, setNotification] = useState(true)
    const scrollToBottom = () => {
        if (messageAreaRef.current) {
            messageAreaRef.current.scrollTop = messageAreaRef.current.scrollHeight;
        }
    }

    useEffect(() => {
        scrollToBottom();
    }, [textMessages, error]);

    
    return (
        <div className={s.wrapper}>
            <div className={s.web_container}>
                <NavigationBar
                    mainPage={t('NavigationMain')}
                    pageName={t('WebAssistantButton')}
                    botsRole={t(`roles.${botsRole.id}`)}
                />
                <div className={s.web_content}>
                    <div className={s.title_body}>
                        <h1>
                            {t('WebAssistantButton')}
                        </h1>
                        <SelectBotsRole />
                    </div>
                    {notification && <div className={s.notification}>
                        <Ring  className={s.ring}/>
                        {t('notification')}
                        <Cross className={s.cross} onClick={() => setNotification(false)} />
                    </div>}
                    <div ref={messageAreaRef} className={s.message_area}>
                        <div className={s.answer}>
                            {t('firstMail')}
                        </div>
                        {
                            textMessages.map((e, index) =>
                                <Answer key={index} e={e} index={0} />)
                        }
                        {status === 'loading' &&
                            <div className={s.loading_message}>
                                <div>.</div>
                                <div>.</div>
                                <div>.</div>
                            </div>
                        }
                        {error
                            && <div className={s.error_message}>
                                <ErrorSvg />
                                <span>Не ўдалося распазнаць запыт. Калі ласка, паўтарыце або змяніце запыт</span>
                            </div>}
                    </div>
                    <InputArea />
                    <DonateWindow />
                    <OtherPlatforms activePlatform={'web'} />
                    <Footer />
                </div>
            </div>
        </div>
    )
}


